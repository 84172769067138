@font-face {
  font-family: "PTSans";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: font-url("PTSans-Regular.ttf");
}

@font-face {
  font-family: "LibreBaskerville";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: font-url("LibreBaskerville-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: font-url("Lato-Regular.ttf");
}