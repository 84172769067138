.cursor-pointer {
  cursor: pointer;
}

svg.main_svg {
  width: 100%;
  height: 100%;
  color: rgb(59, 85, 96);
}

svg.main_svg text {
  fill: currentColor;
}

rect.card-female, .card-female .card-body-rect, .card-female .text-overflow-mask {
  fill: lightgray;
}

rect.card-male, .card-male .card-body-rect, .card-male .text-overflow-mask {
  fill: lightgray;
}

.card-genderless .card-body-rect, .card-genderless .text-overflow-mask {
  fill: lightgray;
}

a.no-style:link {
  text-decoration: inherit;
  color: inherit;
}

a.no-style:hover {
  color: white;
  background-color: rgb(14 109 101 / var(--tw-text-opacity));
}

.no-style rect.card-body-rect:hover {
  fill: rgb(14 109 101 / var(--tw-text-opacity));
}

a.no-style:visited {
  text-decoration: inherit;
  color: inherit;
}

.card_add .card-body-rect {
  fill: rgb(59, 85, 96);
  stroke-width: 4px;
  stroke: #fff;
  cursor: pointer;
}

g.card_add text {
  fill: #fff;
}

.card-main {
  stroke: #000;
}


/* card_family_tree element */
.card_family_tree rect {
  transition: .3s;
}
.card_family_tree:hover rect {
  transform: scale(1.1);
}

/* card_family_tree element */
.card_add_relative {
  cursor: pointer;
  color: #fff;
  transition: .3s;
}
.card_add_relative circle {
  fill: rgba(0,0,0,0);
}
.card_add_relative:hover {
  color: black;
}

/* pencil_icon element */
.card_edit.pencil_icon {
  color: #fff;
  transition: .3s;
}
.card_edit.pencil_icon:hover {
  color: black;
}

/* link element */
.card_break_link, .link_upper, .link_lower, .link_particles {
  transform-origin: 50% 50%;
  transition: 1s;
}
.card_break_link {
  color: #fff;
}
.card_break_link.closed .link_upper {
  transform: translate(-140.5px,655.6px);
}
.card_break_link.closed .link_upper g {
  transform: rotate(-58deg);
}
.card_break_link.closed .link_lower {
}
.card_break_link.closed .link_particles {
  transform: scale(0);
}

/* create-tree styles */
.input-field input {
  height: 2.5rem!important;
}
.input-field>label:not(.label-icon).active {
  -webkit-transform: translateY(-8px) scale(0.8);
  transform: translateY(-8px) scale(0.8);
}
