/*
  Use the following to reproduce a Tailwind UI Select field with Tom Select
*/

/* Native tom-select styles */
@import 'tom-select/dist/css/tom-select';

/* Override tom-select styles */
.ts-control {
  @apply py-0 border-none bg-white;
}

.ts-control input {
  @apply text-base dark:text-slate-50;
}

.ts-wrapper.form-select {
  @apply pr-3;
}

.ts-wrapper.form-select .ts-control {
  @apply pl-0 text-base bg-white dark:text-slate-50 dark:bg-dark-700  dark:placeholder-gray-400;
}

.ts-wrapper.form-select.input-active .ts-control {
  @apply pl-0 bg-white dark:text-slate-50 dark:bg-dark-700  dark:placeholder-gray-400;
}

.ts-wrapper.plugin-remove_button .item {
  @apply rounded-md;
}
.ts-wrapper.multi .ts-control > div {
  @apply p-0 m-0 pl-1;
}

.ts-wrapper.plugin-clear_button .clear-button {
  @apply absolute right-1 text-base-900 dark:text-slate-300
}

.ts-wrapper.plugin-remove_button .item .remove {
  @apply border-none text-lg leading-none py-1 rounded-r-lg;
}

.ts-wrapper .ts-control .item {
  @apply flex flex-row justify-start gap-2;
}

.ts-dropdown {
  @apply mt-1 px-2 py-1 rounded-2xl border-gray-300 drop-shadow-md dark:border-zinc-300 dark:border-zinc-500/80 dark:border-t-zinc-500/80 bg-white text-zinc-900 dark:text-slate-50 dark:bg-zinc-800;
}

.ts-dropdown [data-selectable].option, .ts-dropdown .no-results {
  @apply py-2 rounded-md;
}

.ts-dropdown [data-selectable].option:first-child {
  @apply rounded-t-2xl;
}

.ts-dropdown [data-selectable].option:last-child {
  @apply rounded-b-2xl;
}

.ts-dropdown .create:hover, .ts-dropdown .option:hover, .ts-dropdown .active {
  @apply text-zinc-900 dark:text-slate-50 bg-zinc-300  dark:bg-zinc-700;
}

.ts-dropdown .spinner {
  @apply h-auto w-auto;
}

.ts-dropdown .spinner:after {
  @apply h-5 w-5 border-2 p-0 my-0 inline-block;
}

.ts-wrapper:not(.form-control):not(.form-select).single .ts-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  print-color-adjust: exact;
}
