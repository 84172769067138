.flatpickr-calendar {
  @apply shadow-2xl bg-white touch-manipulation box-border sm:w-[430px] w-[307.875px] absolute rounded-xl leading-6 invisible hidden opacity-0 text-sm border-zinc-300/90 border p-1 dark:border-zinc-700/80 dark:bg-zinc-800 dark:text-zinc-50;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  @apply opacity-100 max-h-[640px] visible;
}

.flatpickr-calendar.open {
  @apply inline-block z-[99999];
}

.flatpickr-calendar .numInput {
  @apply focus:border-none focus:outline-none focus:appearance-none focus:shadow-none focus:border-0 focus:border-transparent focus:ring-0 focus:ring-transparent focus:outline-offset-0;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: -2px 0 0 theme("colors.zinc.100"),
    5px 0 0 theme("colors.zinc.100");
  box-shadow: -2px 0 0 theme("colors.zinc.100"),
    5px 0 0 theme("colors.zinc.100");
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  @apply border-b-0 rounded-l-none rounded-r-none;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  @apply border-l-0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  @apply border-t border-zinc-100 dark:border-zinc-700 h-[40px];
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
  display: none;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
  display: none;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  @apply hidden border-zinc-100;
}

.flatpickr-calendar.arrowTop:after {
  @apply border-white;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  @apply border-zinc-100;
}

.flatpickr-calendar.arrowBottom:after {
  @apply border-white;
}

.flatpickr-calendar:focus {
  @apply outline-0;
}

.flatpickr-wrapper {
  @apply relative inline-block;
}

.flatpickr-months {
  @apply flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 60px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 13px;
  height: 40px;
  width: 40px;
  z-index: 3;
  /* color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); */
  fill: theme("colors.zinc.600");

  @apply sm:bg-white sm:hover:bg-zinc-50/50 sm:hover:shadow-sm text-zinc-800/80 sm:hover:border border-transparent hover:border-zinc-300/80 shadow-zinc-300/20 focus:ring-4 focus:ring-zinc-100/70 focus:border-zinc-300/90 sm:dark:bg-zinc-700/60 sm:dark:text-slate-50 sm:dark:border-zinc-500/30 sm:dark:shadow-zinc-950/40 sm:dark:hover:bg-zinc-800/90 sm:dark:focus:ring-zinc-700/80 sm:dark:focus:border-zinc-400/80  items-center justify-center rounded-full ring-4 ring-transparent text-sm transition-colors ease-in-out duration-300 font-medium font-heading flex text-zinc-600;
}

@media (prefers-color-scheme: dark) {
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    fill: theme("colors.zinc.400");
  }
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  @apply hidden;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  @apply relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  left: 6px; /*
      /*rtl:end:ignore*/
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  right: 6px; /*
      /*rtl:end:ignore*/
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  /* color: #959ea9; */
  @apply text-zinc-400 dark:text-zinc-300;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  @apply h-auto relative;
}

.numInputWrapper input,
.numInputWrapper span {
  @apply inline-block;
}

.numInputWrapper input {
  @apply w-full;
}

.numInputWrapper input::-ms-clear {
  @apply hidden;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  @apply m-0 appearance-none;
}

.numInputWrapper span {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 14px;
  padding: 10px 4px 0 0px;
  line-height: 50%;
  opacity: 1;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
}

.numInputWrapper span.arrowDown:hover,
.numInputWrapper span.arrowUp:hover {
  @apply bg-transparent !important;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid theme("colors.zinc.500");
  border-radius: 3px;
  top: 50%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid theme("colors.zinc.500");
  top: 40%;
  border-radius: 3px;
}

@media (prefers-color-scheme: dark) {
  .numInputWrapper span.arrowUp:after {
    border-bottom-color: theme("colors.zinc.300");
  }
  .numInputWrapper span.arrowDown:after {
    border-top-color: theme("colors.zinc.300");
  }
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  line-height: inherit;
  font-weight: 400;
  color: inherit;
  position: absolute;
  width: 74%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  @apply flex items-center justify-between text-base;
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  @apply px-4 py-2 border border-zinc-300 bg-white font-normal text-base placeholder:text-zinc-500 rounded-r-full block w-auto focus:outline-none focus:ring-4 border-l-0 focus:ring-primary-50 focus:border-primary-500 text-zinc-900  dark:focus:border-zinc-600 dark:text-slate-50 dark:placeholder:text-zinc-400 shadow-sm dark:bg-zinc-800 dark:border-zinc-500 dark:focus:ring-zinc-500/40;

  &[disabled] {
    @apply bg-zinc-100/90 pointer-events-none select-none shadow-none cursor-not-allowed dark:bg-zinc-800/90 dark:opacity-70;
  }
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

@media (prefers-color-scheme: dark) {
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: theme("colors.zinc.300");
  }

  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: theme("colors.zinc.300");
  }
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 400;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  @apply outline-0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply px-4 py-2 border border-zinc-300 bg-white font-normal text-base placeholder:text-zinc-500 rounded-l-full block w-full focus:outline-none focus:ring-4 focus:ring-primary-50 focus:border-primary-500 text-zinc-900  dark:focus:border-zinc-600 dark:text-slate-50 dark:placeholder:text-zinc-400 shadow-sm dark:bg-zinc-800 dark:border-zinc-500 dark:focus:ring-zinc-500/40;

  &[disabled] {
    @apply bg-zinc-100/90 pointer-events-none select-none shadow-none cursor-not-allowed dark:bg-zinc-800/90 dark:opacity-70;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  @apply outline-0;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  @apply cursor-default bg-transparent text-sm text-zinc-700 leading-4 m-0 text-center block flex-1 font-semibold dark:text-zinc-400;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  @apply sm:w-[420px] w-[307.875px] relative flex items-start overflow-hidden;
}

.flatpickr-days:focus {
  @apply outline-0;
}

.dayContainer {
  /* width: 307.875px; */
  /* min-width: 307.875px; */
  /* max-width: 307.875px; */
  @apply sm:w-[420px] sm:max-w-[420px] sm:min-w-[420px] w-[307.875px] max-w-[307.875px] min-w-[307.875px] p-0 outline-0 text-left box-border flex flex-wrap justify-around opacity-100 transform-none;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 theme("colors.zinc.100");
  box-shadow: -1px 0 0 theme("colors.zinc.100");
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 400;
  /* width: 14.2857143%; */
  /* -webkit-flex-basis: 14.2857143%; */
  /* -ms-flex-preferred-size: 14.2857143%; */
  /* flex-basis: 14.2857143%; */
  /* max-width: 39px; */
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  flex: 1 0 calc(14.2857% - 10px);
  max-width: calc(14.2857% - 10px);
  margin: 5px;
  color: theme("colors.zinc.700");
}

@media (prefers-color-scheme: dark) {
  .flatpickr-day {
    color: theme("colors.zinc.50");
  }
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  @apply cursor-pointer outline-0 bg-zinc-100 border-zinc-100 dark:bg-zinc-700 dark:border-zinc-700;
}

.flatpickr-day.today {
  @apply border-zinc-300/80 dark:text-slate-50;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  @apply border-zinc-300/80 bg-zinc-300/80 text-white dark:bg-zinc-800 border-zinc-800 dark:text-zinc-300;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply text-white bg-primary-500 border-primary-500 dark:text-slate-50 shadow-none;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 theme("colors.primary.500");
  box-shadow: -10px 0 0 theme("colors.primary.500");
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 theme("colors.zinc.100"),
    5px 0 0 theme("colors.zinc.100");
  box-shadow: -5px 0 0 theme("colors.zinc.100"),
    5px 0 0 theme("colors.zinc.100");
}

@media (prefers-color-scheme: dark) {
  .flatpickr-day.inRange {
    -webkit-box-shadow: -5px 0 0 theme("colors.zinc.700"),
      5px 0 0 theme("colors.zinc.800");
    box-shadow: -5px 0 0 theme("colors.zinc.700"),
      5px 0 0 theme("colors.zinc.700");
  }
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  @apply text-zinc-300 dark:text-zinc-600 border-transparent bg-transparent cursor-default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 theme("colors.primary.500"),
    5px 0 0 theme("colors.primary.500");
  box-shadow: -5px 0 0 theme("colors.primary.500"),
    5px 0 0 theme("colors.primary.500");
}

.flatpickr-day.hidden {
  @apply invisible;
}

.rangeMode .flatpickr-day {
  @apply mt-px;
}

.flatpickr-weekwrapper {
  @apply float-left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 theme("colors.zinc.100");
  box-shadow: 1px 0 0 theme("colors.zinc.100");
}

@media (prefers-color-scheme: dark) {
  .flatpickr-weekwrapper .flatpickr-weeks {
    -webkit-box-shadow: -5px 0 0 theme("colors.zinc.800"),
      5px 0 0 theme("colors.zinc.800");
    box-shadow: -5px 0 0 theme("colors.zinc.800"),
      5px 0 0 theme("colors.zinc.800");
  }
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  @apply border-zinc-700 dark:border-zinc-300;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  @apply border-zinc-700 dark:border-zinc-300;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  @apply m-0 p-0 h-auto leading-4 text-zinc-700 dark:text-zinc-300 relative text-sm box-border rounded-none text-center shadow-none border-none bg-transparent appearance-none;
}

.flatpickr-time input.flatpickr-hour {
  @apply font-bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  @apply font-normal;
}

.flatpickr-time input:focus {
  @apply outline-0 border-0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  @apply bg-zinc-300 dark:bg-zinc-800;
}

.flatpickr-input[readonly] {
  @apply cursor-pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
