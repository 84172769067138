@import "tailwindcss/base";
@import "tailwindcss/components";

/* Custom components (from Rails UI) */
@import "custom/custom.css";

@import "tailwindcss/utilities";

/* @import "forms.css"; */
@import "animation.css";
@import "slideover.css";
@import "family-chart.css";
@import "local-fonts.scss";
@import "tom-select.css";

@layer base {
  a {
      @apply text-secondary-700 dark:text-secondary-200
  }
}
